.trip-inclusions-section {
    background-color: var(--gunmetal-dark, #333); /* Example dark bg */
    color: var(--white);
  }
  
  .trip-inclusions-section .container {
    position: relative;
  }
  
  /* Full-width underline under the heading */
  .title-row {
    border-bottom: 2px solid var(--white);
    padding-bottom: 0.5rem; /* Space between heading text and underline */
  }
  
  .section-title {
    font-size: 1.5rem;
    margin: 0;
  }
  
  /* Icon styling */
  .inclusion-icon {
    font-size: 1rem;
    color: var(--white);
  }