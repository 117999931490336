.back-program-hero {
    background: url("../public/assets/images/doctor_grid_banner.jpg") center/cover no-repeat;
    height: 400px;
    /* or desired height */
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
}

.back-program-hero::before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.4);
    /* overlay */
    z-index: 1;
}

.back-program-hero-content {
    position: relative;
    z-index: 2;
    text-align: left;
    /* add text-shadow if needed */
}

.back-program-hero-content h1,
.back-program-hero-content p {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
    margin: 0;
    /* Reset default margins if desired */
}

.itinerary-img-container {
    height: 300px;
    /* Adjust to desired height */
    overflow: hidden;
    padding: 0;
}

.pervan-img-container {
    height: 400px;
    /* Adjust to desired height */
    overflow: hidden;
    padding: 0;
}

.itinerary-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

/* Mobile-specific styling */
@media (max-width: 768px) {

    /* Force the layout to be vertical for all itinerary days */
    .itinerary-day {
        flex-direction: column;
    }

    /* Ensure the image always comes first */
    .itinerary-img-container {
        order: 1;
        padding-right: calc(var(--bs-gutter-x)* .5);
        padding-left: calc(var(--bs-gutter-x)* .5);
    }

    /* Text container comes after and gets extra padding */
    .itinerary-text-container {
        order: 2;
        padding: 1rem;
        /* More padding on mobile */
    }

    /* Optionally ensure the inner text wrapper remains 75% width */
    .itinerary-text-container .w-75 {
        width: 75%;
        margin: 0 auto;
        text-align: left;
    }
}